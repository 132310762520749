import React from 'react'

const WebsiteLoader = () => {
	return (
		<div className="leaf" style={{
			position: 'absolute',
			animation: 'spin 3s cubic-bezier(0.5, 0, 0, 1) infinite',
		}}>
			<svg width="150" height="150" viewBox="0 0 275 275" fill="#255c32" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M254.86 69.602C251.724 73.3859 248.644 77.3845 245.377 81.0796C239.402 88.1702 232.511 93.9183 225.282 97.8438C219.721 100.919 214.058 101.725 209.326 100.115C208.476 99.7162 207.774 99.0581 207.269 98.1864C206.788 97.5347 206.491 96.6915 206.403 95.7282C206.332 95.2253 206.852 94.4067 207.272 93.7121C209.958 88.799 213.702 84.6077 217.908 81.8035C222.115 78.9993 226.543 77.7429 230.491 78.2337C233.039 78.3061 235.23 78.1723 237.841 77.9873C238.449 77.9844 239.089 77.8371 239.729 77.553C233.827 75.9529 227.203 76.2684 220.393 78.4739C216.576 80.2375 212.905 83.1653 209.803 86.9207C209.162 87.6839 208.417 88.6944 207.797 89.4553C206.969 88.977 207.31 86.027 208.564 83.7719C211.211 78.278 215.278 73.5795 219.906 70.6703C224.534 67.761 229.359 66.8691 233.361 68.1832C235.95 68.6401 238.45 69.3307 240.842 70.2504C242.656 71.5748 244.886 72.1899 247.33 72.0403C249.774 71.8906 252.354 70.9809 254.838 69.3933L254.86 69.602Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M16.9762 205.617C20.113 201.833 23.1928 197.834 26.4593 194.139C32.4351 187.049 39.3255 181.3 46.5548 177.375C52.1153 174.3 57.7785 173.494 62.5105 175.103C63.361 175.503 64.0626 176.161 64.5675 177.032C65.0489 177.684 65.346 178.527 65.4334 179.491C65.5049 179.993 64.9847 180.812 64.5647 181.507C61.8786 186.42 58.1351 190.611 53.9286 193.415C49.7221 196.219 45.2935 197.476 41.3458 196.985C38.7977 196.913 36.6066 197.046 33.9956 197.231C33.3881 197.234 32.7476 197.382 32.1072 197.666C38.0099 199.266 44.6336 198.95 51.444 196.745C55.2611 194.981 58.9317 192.053 62.0338 188.298C62.6744 187.535 63.4195 186.524 64.0398 185.763C64.8681 186.242 64.5266 189.192 63.2723 191.447C60.6256 196.941 56.5584 201.639 51.9307 204.548C47.303 207.458 42.4778 208.35 38.4758 207.036C35.8862 206.579 33.3868 205.888 30.995 204.968C29.1807 203.644 26.9507 203.029 24.5068 203.178C22.0629 203.328 19.4822 204.238 16.9985 205.825L16.9762 205.617Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M70.1133 18.5252C73.8972 21.662 77.8958 24.7419 81.5909 28.0083C88.6815 33.9841 94.4296 40.8745 98.3551 48.1038C101.43 53.6643 102.236 59.3275 100.627 64.0595C100.227 64.91 99.5695 65.6117 98.6977 66.1165C98.0461 66.598 97.2029 66.895 96.2395 66.9825C95.7367 67.054 94.918 66.5338 94.2234 66.1137C89.3103 63.4276 85.1191 59.6842 82.3149 55.4776C79.5107 51.2711 78.2542 46.8425 78.745 42.8948C78.8174 40.3467 78.6837 38.1557 78.4987 35.5447C78.4957 34.9371 78.3484 34.2966 78.0643 33.6562C76.4642 39.5589 76.7797 46.1826 78.9853 52.993C80.7489 56.8101 83.6766 60.4807 87.4321 63.5828C88.1952 64.2234 89.2057 64.9686 89.9666 65.5888C89.4883 66.4172 86.5383 66.0756 84.2832 64.8213C78.7894 62.1746 74.0909 58.1074 71.1816 53.4797C68.2724 48.852 67.3805 44.0269 68.6946 40.0248C69.1514 37.4352 69.842 34.9358 70.7617 32.544C72.0861 30.7297 72.7013 28.4997 72.5516 26.0558C72.402 23.6119 71.4922 21.0312 69.9046 18.5475L70.1133 18.5252Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M204.628 253.811C200.844 250.675 196.845 247.595 193.15 244.328C186.06 238.353 180.312 231.462 176.386 224.233C173.311 218.672 172.505 213.009 174.115 208.277C174.514 207.427 175.172 206.725 176.044 206.22C176.695 205.739 177.538 205.442 178.502 205.354C179.005 205.283 179.823 205.803 180.518 206.223C185.431 208.909 189.622 212.653 192.426 216.859C195.231 221.066 196.487 225.494 195.996 229.442C195.924 231.99 196.058 234.181 196.243 236.792C196.246 237.4 196.393 238.04 196.677 238.68C198.277 232.778 197.962 226.154 195.756 219.344C193.992 215.527 191.065 211.856 187.309 208.754C186.546 208.113 185.536 207.368 184.775 206.748C185.253 205.92 188.203 206.261 190.458 207.515C195.952 210.162 200.65 214.229 203.56 218.857C206.469 223.485 207.361 228.31 206.047 232.312C205.59 234.901 204.899 237.401 203.98 239.793C202.655 241.607 202.04 243.837 202.19 246.281C202.339 248.725 203.249 251.305 204.837 253.789L204.628 253.811Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M205.113 19.0512C204.288 23.8965 203.62 28.8994 202.639 33.7326C201.009 42.8612 197.916 51.2843 193.618 58.2985C190.339 63.7417 185.838 67.2715 180.935 68.2436C179.999 68.3232 179.063 68.1042 178.19 67.6016C177.447 67.278 176.768 66.6963 176.21 65.9057C175.897 65.506 175.938 64.5369 175.955 63.7253C175.824 58.1274 176.971 52.626 179.212 48.0942C181.452 43.5625 184.66 40.2601 188.324 38.7112C190.567 37.4999 192.397 36.2885 194.566 34.8228C195.091 34.5165 195.572 34.0686 195.984 33.5024C190.072 35.068 184.494 38.6531 179.699 43.9684C177.275 47.4043 175.56 51.775 174.751 56.5784C174.578 57.5596 174.438 58.8073 174.281 59.7764C173.324 59.7764 172.145 57.0509 172.104 54.4707C171.649 48.3896 172.822 42.2869 175.375 37.4536C177.928 32.6203 181.661 29.4353 185.784 28.5723C188.255 27.6731 190.765 27.0215 193.296 26.622C195.53 26.8619 197.768 26.2796 199.81 24.9281C201.852 23.5765 203.632 21.4983 204.989 18.8816L205.113 19.0512Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M67.106 255.785C67.9306 250.94 68.5986 245.937 69.5799 241.104C71.2098 231.975 74.303 223.552 78.601 216.538C81.8791 211.095 86.3804 207.565 91.2833 206.593C92.2194 206.513 93.1561 206.733 94.0291 207.235C94.7719 207.559 95.4508 208.14 96.0082 208.931C96.3215 209.331 96.2803 210.3 96.2639 211.111C96.3942 216.709 95.2479 222.211 93.007 226.742C90.7662 231.274 87.5591 234.577 83.8949 236.125C81.652 237.337 79.8214 238.548 77.6527 240.014C77.128 240.32 76.647 240.768 76.2344 241.334C82.1463 239.769 87.7249 236.184 92.5201 230.868C94.944 227.432 96.659 223.062 97.4677 218.258C97.6409 217.277 97.781 216.029 97.9377 215.06C98.8942 215.06 100.073 217.786 100.115 220.366C100.57 226.447 99.3965 232.55 96.8434 237.383C94.2903 242.216 90.5576 245.401 86.4347 246.264C83.9636 247.164 81.4537 247.815 78.9225 248.215C76.6891 247.975 74.4503 248.557 72.4086 249.909C70.367 251.26 68.5869 253.338 67.2297 255.955L67.106 255.785Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M19.5785 67.191C24.4238 68.0156 29.4267 68.6836 34.2599 69.6648C43.3885 71.2947 51.8116 74.3879 58.8259 78.686C64.269 81.9641 67.7988 86.4654 68.7709 91.3682C68.8505 92.3043 68.6315 93.241 68.1289 94.1141C67.8053 94.8569 67.2236 95.5357 66.4331 96.0931C66.0333 96.4065 65.0642 96.3653 64.2527 96.3488C58.6548 96.4791 53.1533 95.3329 48.6215 93.092C44.0898 90.8511 40.7874 87.6441 39.2385 83.9799C38.0272 81.737 36.8158 79.9063 35.3501 77.7376C35.0438 77.213 34.5959 76.7319 34.0298 76.3194C35.5953 82.2313 39.1804 87.8098 44.4957 92.6051C47.9316 95.029 52.3024 96.7439 57.1058 97.5527C58.0869 97.7259 59.3346 97.866 60.3037 98.0226C60.3037 98.9792 57.5782 100.158 54.998 100.2C48.9169 100.655 42.8142 99.4814 37.9809 96.9284C33.1476 94.3753 29.9626 90.6425 29.0996 86.5196C28.2004 84.0485 27.5488 81.5387 27.1494 79.0075C27.3892 76.774 26.807 74.5352 25.4554 72.4936C24.1039 70.4519 22.0256 68.6718 19.4089 67.3147L19.5785 67.191Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M253.715 203.697C248.869 202.873 243.867 202.205 239.033 201.224C229.905 199.594 221.482 196.5 214.467 192.202C209.024 188.924 205.494 184.423 204.522 179.52C204.443 178.584 204.662 177.647 205.164 176.774C205.488 176.032 206.07 175.353 206.86 174.795C207.26 174.482 208.229 174.523 209.041 174.54C214.638 174.409 220.14 175.556 224.672 177.796C229.203 180.037 232.506 183.244 234.055 186.909C235.266 189.151 236.477 190.982 237.943 193.151C238.249 193.675 238.697 194.156 239.263 194.569C237.698 188.657 234.113 183.079 228.797 178.283C225.362 175.859 220.991 174.145 216.187 173.336C215.206 173.163 213.959 173.022 212.989 172.866C212.989 171.909 215.715 170.73 218.295 170.689C224.376 170.234 230.479 171.407 235.312 173.96C240.146 176.513 243.331 180.246 244.194 184.369C245.093 186.84 245.744 189.35 246.144 191.881C245.904 194.114 246.486 196.353 247.838 198.395C249.189 200.437 251.268 202.217 253.884 203.574L253.715 203.697Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M136.754 0.146786C138.463 4.75528 140.386 9.42183 141.953 14.0982C145.105 22.8187 146.638 31.66 146.423 39.8835C146.306 46.2365 144.172 51.544 140.412 54.8373C139.641 55.3742 138.721 55.6529 137.713 55.6542C136.908 55.7454 136.03 55.581 135.152 55.1751C134.68 54.9856 134.231 54.1258 133.84 53.4146C130.928 48.6319 129.17 43.2943 128.845 38.2493C128.52 33.2042 129.646 28.7408 132.045 25.5673C133.381 23.3968 134.361 21.4324 135.506 19.0787C135.808 18.5511 136 17.9228 136.075 17.2261C131.737 21.5379 128.699 27.432 127.204 34.4328C126.822 38.6203 127.523 43.2629 129.224 47.8272C129.565 48.7635 130.067 49.9141 130.416 50.8317C129.587 51.3099 127.203 49.5392 125.878 47.3253C122.443 42.2863 120.408 36.4147 120.202 30.9524C119.997 25.4901 121.637 20.8654 124.776 18.0566C126.466 16.0424 128.314 14.2231 130.306 12.6116C132.36 11.7026 134.008 10.0789 135.101 7.88763C136.193 5.69632 136.695 3.0065 136.562 0.0617629L136.754 0.146786Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M135.604 274.168C133.896 269.56 131.973 264.893 130.406 260.217C127.253 251.496 125.72 242.655 125.935 234.431C126.053 228.078 128.186 222.771 131.946 219.478C132.717 218.941 133.638 218.662 134.645 218.661C135.45 218.57 136.329 218.734 137.207 219.14C137.678 219.329 138.127 220.189 138.519 220.9C141.43 225.683 143.188 231.021 143.514 236.066C143.839 241.111 142.713 245.574 140.314 248.748C138.977 250.918 137.997 252.883 136.852 255.236C136.551 255.764 136.358 256.392 136.284 257.089C140.621 252.777 143.66 246.883 145.155 239.882C145.536 235.695 144.836 231.052 143.135 226.488C142.794 225.551 142.291 224.401 141.943 223.483C142.771 223.005 145.155 224.776 146.481 226.99C149.915 232.029 151.951 237.9 152.156 243.363C152.362 248.825 150.722 253.45 147.583 256.258C145.892 258.273 144.044 260.092 142.052 261.703C139.998 262.612 138.35 264.236 137.258 266.427C136.166 268.619 135.663 271.308 135.796 274.253L135.604 274.168Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M0.146878 134.604C4.75538 132.896 9.42192 130.973 14.0983 129.406C22.8188 126.253 31.6601 124.72 39.8836 124.935C46.2366 125.053 51.5441 127.186 54.8374 130.946C55.3743 131.717 55.653 132.638 55.6543 133.645C55.7455 134.45 55.5811 135.329 55.1752 136.207C54.9857 136.678 54.1259 137.127 53.4147 137.518C48.632 140.43 43.2944 142.188 38.2494 142.514C33.2043 142.839 28.7408 141.713 25.5674 139.314C23.3969 137.977 21.4325 136.997 19.0788 135.852C18.5512 135.551 17.9229 135.358 17.2262 135.284C21.538 139.621 27.4321 142.66 34.4329 144.155C38.6204 144.536 43.263 143.836 47.8273 142.135C48.7636 141.794 49.9142 141.291 50.8318 140.943C51.31 141.771 49.5393 144.155 47.3254 145.481C42.2864 148.915 36.4148 150.951 30.9525 151.156C25.4902 151.362 20.8655 149.722 18.0567 146.583C16.0425 144.892 14.2232 143.044 12.6117 141.052C11.7026 138.998 10.079 137.35 7.88773 136.258C5.69641 135.165 3.00659 134.663 0.0618553 134.796L0.146878 134.604Z" />
				<path fill-rule="evenodd" fill="#255c32" clip-rule="evenodd" d="M271.168 135.754C266.56 137.463 261.893 139.386 257.217 140.953C248.496 144.105 239.655 145.638 231.431 145.423C225.078 145.306 219.771 143.172 216.478 139.412C215.941 138.641 215.662 137.721 215.661 136.713C215.569 135.908 215.734 135.03 216.14 134.152C216.329 133.68 217.189 133.231 217.9 132.84C222.683 129.928 228.02 128.17 233.066 127.845C238.111 127.52 242.574 128.646 245.748 131.045C247.918 132.381 249.882 133.361 252.236 134.506C252.764 134.808 253.392 135 254.089 135.074C249.777 130.737 243.883 127.699 236.882 126.204C232.695 125.822 228.052 126.523 223.488 128.224C222.551 128.565 221.401 129.067 220.483 129.416C220.005 128.587 221.776 126.203 223.989 124.878C229.028 121.443 234.9 119.408 240.362 119.202C245.825 118.997 250.449 120.637 253.258 123.776C255.272 125.466 257.092 127.314 258.703 129.306C259.612 131.36 261.236 133.008 263.427 134.101C265.618 135.193 268.308 135.695 271.253 135.562L271.168 135.754Z" />
			</svg>
		</div>
	);
}

export default WebsiteLoader
